import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import { Header } from "~/components/Header";
import Footer from "~/components/Footer";

export function links() {
  return [
    {
      rel: "icon",
      href: "/favicon.ico",
      type: "image/x-icon",
      sizes: "any",
    },
    {
      rel: "preload",
      href: "/fonts/Bee_Four.otf",
      as: "font",
      type: "font/otf",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      href: "/fonts/Bee_Four.otf",
      as: "font",
      type: "font/otf",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      href: "/fonts/Satoshi-Variable.woff2",
      as: "font",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
  ];
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="de" className="scroll-smooth bg-black text-white">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Header />
        {children}
        <Footer />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
