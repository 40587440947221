import { Link, LinkProps, NavLink } from "@remix-run/react";
import { CustomButtonLink } from "~/components/ui/CustomButton";
import { ReactNode } from "react";

export function Header() {
  return (
    <header className="sticky top-0 z-40 flex items-center justify-center border-b border-black bg-black px-5 lg:px-16">
      <nav className="flex w-[1198px] max-w-full justify-between gap-5 py-3">
        <Link to="/" className="my-auto" prefetch="intent">
          <img
            src="/logo.svg"
            className="max-w-full self-start"
            alt="Voll Logo"
            loading="eager"
          />
        </Link>

        <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform justify-between gap-5 whitespace-nowrap text-pl max-md:hidden lg:space-x-4">
          <NavItem
            to={{
              pathname: "/",
              hash: "#spirit",
            }}
          >
            Spirit
          </NavItem>
          <NavItem
            to={{
              pathname: "/",
              hash: "#drinks",
            }}
          >
            Drinks
          </NavItem>
          <NavItem
            to={{
              pathname: "/",
              hash: "#region",
            }}
          >
            Region
          </NavItem>
          <NavItem
            to={{
              pathname: "/",
              hash: "#team",
            }}
          >
            Team
          </NavItem>
          <NavItem
            to={{
              pathname: "/",
              hash: "#shop",
            }}
          >
            Shop
          </NavItem>
        </div>

        <CustomButtonLink
          to={{
            pathname: "/",
            hash: "#kontakt",
          }}
        >
          Weinschorle kaufen
        </CustomButtonLink>
      </nav>
    </header>
  );
}

type NavItemProps = LinkProps & { children: ReactNode };

function NavItem({ children, ...props }: NavItemProps) {
  return (
    <NavLink
      className={({ isActive }) => `py-2 hover:underline ${isActive ? "" : ""}`}
      prefetch="intent"
      {...props}
    >
      {children}
    </NavLink>
  );
}
