import { Link, LinkProps, useFetcher } from "@remix-run/react";
import { ReactNode } from "react";
import { CustomButton } from "~/components/ui/CustomButton";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  //todo adjust fonts, fix mobile layout

  return (
    <footer className="flex flex-col items-center px-5 pt-16">
      <NewsletterPart className="md:hidden" />
      <div className="flex w-[1197px] max-w-full items-end gap-5 px-3">
        <img
          src="/images/Weinschorle_Footer.webp"
          className="max-w-full shrink-0 self-end"
          alt="Weinschorle Dose"
          loading="lazy"
        />
        <div className="my-auto flex w-full justify-between max-md:flex-col">
          <NewsletterPart className="max-md:hidden" />

          <div className="my-auto max-w-96">
            <p className="text-m-h3 sm:text-h4">Nichts mehr verpassen?</p>
            <p className="text-m-pm sm:text-pm">
              Auf unseren Social Media Kanälen findest du alle News zu Events,
              Rabatten und neuen Produkten. #volldabei
            </p>
            <div className="mt-7 flex items-center space-x-4 max-sm:pb-6">
              <SocialIcon
                to="https://www.facebook.com/people/Voll-Drinks/61560868250557/"
                platform="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              />
              <SocialIcon
                to="https://www.instagram.com/voll.drinks/"
                platform="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              />
              <SocialIcon
                to="https://www.tiktok.com/@voll.dabei"
                platform="TikTok"
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-[1197px] max-w-full items-center gap-5 border-t-2 border-white pb-10 pt-9 max-md:flex-col md:justify-between">
        <span className="my-auto text-center text-m-pxs sm:text-pm">
          <b>© {currentYear} UPdrinks GmbH – </b>Lilienthalstraße 51, 64625
          Bensheim
        </span>
        <div className="lg:space-x-8 xl:space-x-16">
          <FooterLink to="/agb">AGBs</FooterLink>
          <FooterLink to="/impressum">Impressum</FooterLink>
          <FooterLink to="/datenschutz">Datenschutz</FooterLink>
        </div>
      </div>
    </footer>
  );
}

type ActionDataSuccess = {
  success: true;
};

type ActionDataError = {
  error: string;
};

type ActionData = ActionDataSuccess | ActionDataError;

function NewsletterPart({ className = "" }: { className?: string }) {
  const fetcher = useFetcher<ActionData>();
  const { data, state } = fetcher;

  return (
    <div
      className={`${className} mt-7 items-center justify-between gap-5 max-md:pb-[3.75rem]`}
    >
      <p className="text-m-h3 sm:text-h4">
        Lust auf Rabatte & exklusive Deals?
      </p>
      <p className="text-m-pm sm:text-pm">
        Melde dich zu unserem kostenlosen Newsletter an.
      </p>
      <fetcher.Form method="post" action="/registernewsletter">
        <div className="mt-8 flex">
          <input
            className="border-y border-l border-white border-opacity-50 bg-transparent px-4 py-3 text-m-pm md:text-pm"
            type="email"
            name="email"
            placeholder="Deine E-Mail Adresse"
            required
          />
          <CustomButton className="px-6 py-3" type="submit">
            {state === "submitting" ? "Anmelden..." : "Anmelden"}
          </CustomButton>
        </div>
        {data && "error" in data && (
          <p className="mt-2 text-red-500">{data.error}</p>
        )}
        {data && "success" in data && data.success && (
          <p className="mt-2 text-green-500">
            Vielen Dank für Ihre Anmeldung! Bitte überprüfen Sie Ihre Mailbox.
          </p>
        )}
      </fetcher.Form>
    </div>
  );
}

type FooterLinkProps = LinkProps & {
  children: ReactNode;
};

function FooterLink({ to, children, ...props }: FooterLinkProps) {
  return (
    <Link
      to={to}
      className="whitespace-nowrap p-1.5 text-m-ps hover:underline sm:text-pm"
      prefetch="intent"
      {...props}
    >
      {children}
    </Link>
  );
}

type SocialIconProps = LinkProps & {
  platform: string;
};

function SocialIcon({ to, platform, ...props }: SocialIconProps) {
  return (
    <Link to={to} {...props}>
      <img
        className="size-8 shrink-0"
        src={`/icons/social/${platform}.svg`}
        loading="lazy"
        alt={`${platform} Icon`}
      />
    </Link>
  );
}
